import Favorite from 'components/favorites/Favorite'
import { Step } from './Step'
import './stepper.css'
import { Exercise } from 'pages/aprendizaje/modulo/components/Exercise'
import { Divider } from 'components/Divider'

interface IStepper { 
    data: any[],
}

export const VerticalStepper: React.FC <IStepper>= ({ data })=>{ 
    const result = data.find((item: any) => item.createdAt >= '1739930740000');

    return(
        <div className="stepper-container">
            {
                data.length !== 0 ?
                data.map( (element:any, index) => { 
                    
                    return( 
                    <>
                        {element.idExercise === result?.idExercise && (
                            <>
                            <div style={{"color":'#D64200', fontWeight: "500", fontSize: '24px', marginBottom: "15px", textAlign: "center", textDecoration: "underline"}}>Enseñanza en Campus</div></>)
                        }
                        <div style={{ width:'100%', display:'flex' }} key={element.idExercise}>
                            <Step index={index} data={data}  children={<Exercise exercises={data} index={index} exercise={element} />} state={element.completed} />
                        </div>
                    </>)
                })
                :
                null
            }
        </div>
    )
}